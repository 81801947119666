export const zavodBytHimii: [string, number][] = [
    // 10624
    ['нейтрализатор неприятных запахов и меток', 5000],
    // 10617
    ['шампунь для кошек и собак с белой шерстью', 3000],
    // 10616
    ['шампунь для кошек с пантенолом', 5000],
    // 10615
    ['шампунь для кошек и собак всех пород', 9000],
    // 10614
    ['шампунь для собак с длинной шерстью', 7000],
]