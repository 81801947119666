import distributeToRemainingColumns from "./distribute-to-remaining-columns"

export function createLayouts(kinds: [string, number][], columns: number, layouts: ([string, number][])[] = []) {
    if (kinds.length > 1) {
        const [layout, rest] = createLayout(kinds, columns)
        layouts.push(layout)
        createLayouts(rest, columns, layouts)  
    } else if (kinds.length === 1) {
        layouts.push(kinds)
    } 

    layouts = layouts.map((item) => distributeToRemainingColumns(item, columns))

    return layouts
}

function createLayout(kinds: [string, number][], columns: number) {

    let filledColumns: [string, number][] = []
    const [minKind, restKinds] = getMin(kinds)
    filledColumns.push(minKind)

    let freeCols = columns - 1
    let list = restKinds

    while (freeCols > 0) {
        const result = findKind(list, freeCols, minKind[1])
        // console.log(result)

        if (result) {
            const [kind, cols, restList] = result

            for (let i = 1; i <= cols; i++) {
                const [name, amount] = kind
                filledColumns.push([name, amount/cols])
                list = restList
            }

            freeCols -= cols
        } else {
            // является не совместимым, значит идет отдельно тогда делим текущий на 3 части и распихиваем по колонкам или одной
            freeCols = 0
        }
    }

    return [filledColumns, list]
}

function findKind(kinds: [string, number][], columns: number, size: number): [[string, number], number, [string, number][]] | null {
    for (let i = 1; i <= columns; i++) {
        const kind = kinds.find((kind, j) => {
            const sizePerOneCol = kind[1] / i // кол-во блоков в колонке
            
            if (sizePerOneCol === size) {
                return true
            }
        })

        if (kind) {
            const index = kinds.indexOf(kind)
            // нашли минимальный тираж, который можно разделить на 1, 2, 3 кол
            return [kind, i, kinds.filter((_, k) => k !== index)]
        }
    }

    return null
}

function getMin(kinds: [string, number][]): [[string, number], [string, number][]] {
    let min: [string, number] = ['noName', 2000000]
    let indexMin = 0

    kinds.forEach(([name, amount], i) => {
        if (amount < min[1]) {
            min = [name, amount]
            indexMin = i
        }
    })

    return [ min, kinds.filter((_, i) => i !== indexMin) ]
}