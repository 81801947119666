import Typography from 'antd/es/typography/Typography';
import CombinationsForm from './components/combinations-form';
import ListOfEditions from './components/list-of-editions';
import { Flex, Form } from 'antd';
import Title from 'antd/es/typography/Title';
import { useState } from 'react';
import { useForm } from 'antd/es/form/Form';
import { FormValues } from './types/form-values';

function App() {
  const [form] = useForm<FormValues>()

  const initialValues = {list: [{name: '', amount: 0}]}
  const [list, setList] = useState<[string, number][][]>([])

  return (
    <Form style={{width: '100%'}} form={form} initialValues={initialValues}>
      <Flex style={{maxWidth: 1000, margin: '0 auto'}} gap={24}  vertical>
        <Title>Комбинатор сборок</Title>
        <CombinationsForm list={list} onSuccessCreation={setList}/>
        <ListOfEditions list={list}/>
      </Flex>
    </Form>
  );
}

export default App;
