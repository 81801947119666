import { DeleteOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, InputNumber, Row, Space, Upload } from 'antd'
import * as React from 'react'
import {FC} from 'react'
 
interface FormListProps {
 
}
 
const FormList: FC<FormListProps> = () =>  {
    return (
        <Form.List name={'list'}>
                {(fields, { add, remove }) => {
                    return <Space style={{width: '100%'}} size={8} direction={'vertical'}>
                        {
                            fields.map(field => (
                                <Row gutter={24} key={field.key}>
                                    {/* <Col sm={2}>
                                        <Form.Item name={[field.name, 'img']} valuePropName={'fileList'} noStyle>
                                            <Upload ><Button icon={<UploadOutlined/>}/></Upload>
                                        </Form.Item>
                                    </Col> */}
                                    <Col sm={20}>
                                        <Form.Item name={[field.name, 'name']} noStyle>
                                            <Input/>
                                        </Form.Item>
                                    </Col>
                                    <Col >
                                        <Form.Item name={[field.name, 'amount']} noStyle>
                                            <InputNumber formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}/>
                                        </Form.Item>
                                    </Col>
                                    <Col >
                                        <Button danger icon={<DeleteOutlined/>} onClick={() => remove(field.name)}/>
                                    </Col>
                                </Row>
                            ))
                        }

                        <Button icon={<PlusOutlined/>}  onClick={() => add()}>Добавить</Button>
                    </Space>
                }
                }
            </Form.List>
    )
}
 
export default React.memo(FormList)