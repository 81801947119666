export default function distributeToRemainingColumns (filledColumns: [string, number][], columns: number): [string, number][] {
    let resortedColumns: [string, number][] = [];

    const numberOfUnfilledColumns = columns - filledColumns.length
    const numberOfFilledColumns = filledColumns.length 
    const numberOfColsPerOneEdition = columns / numberOfFilledColumns // сколько от тиража в 1 колонку

    if (numberOfFilledColumns === 1) {
        const [name, amount] = filledColumns[0]
        const divider = columns 
        const sizePerOneCol = Math.ceil(amount / divider)
        for (let i = 0; i < numberOfColsPerOneEdition; i++) {
            resortedColumns.push([name, sizePerOneCol])
        }
        return resortedColumns;
    } else if ((numberOfUnfilledColumns >= numberOfFilledColumns) && (numberOfUnfilledColumns % numberOfFilledColumns) === 0) {
        // если количество заполненых колонок можно разделить на оставшиеся, то делим
        filledColumns.forEach(([name, amount]) => {
            const divider = numberOfUnfilledColumns
            const sizePerOneCol = Math.ceil(amount / divider)
            for (let i = 0; i < numberOfColsPerOneEdition; i++) {
                resortedColumns.push([name, sizePerOneCol])
            }
        })
        return resortedColumns;
    } else {
        return filledColumns
    }

}