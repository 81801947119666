import { Typography } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import * as React from 'react'
import {FC} from 'react'
import { FormValues } from '../types/form-values'

const {Text} = Typography
 
interface BuildInfoProps {
    list: any[]
}
 
const BuildInfo: FC<BuildInfoProps> = ({list}) =>  {
    const listOfEditions = useWatch('list') || []

    return (
        <Text><Text strong>{listOfEditions.length}</Text> тиражей сформированы в <Text strong>{list.length}</Text> сборок</Text>
    )
}
 
export default BuildInfo