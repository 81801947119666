import { CopyOutlined,   ShareAltOutlined, } from '@ant-design/icons'
import copy from 'copy-to-clipboard';
import { Button, Col, Flex, Form, InputNumber, Row, Space } from 'antd'
import { FC } from 'react'
import { prodactinvest } from '../constants/prodactinvest'
import { detoks365 } from '../constants/365-detoks'
import { amp } from '../constants/amp'
import { liniyaVkusa } from '../constants/liniya-vkusa';
import { zavodBytHimii } from '../constants/zavod-byt-himii'
import { createLayouts } from '../helpers/create-layouts'
import { FormValues } from '../types/form-values'
import BuildInfo from './build-info'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import FormList from './combinations-form/form-list'

const createList = (list: [string, number][]) => {
    return list.map(([name, amount]) => ({
        name,
        amount
    }))
}

interface CombinationsFormProps {
    list: [string, number][][]
    onSuccessCreation: (list: [string, number][][]) => void
}

const CombinationsForm: FC<CombinationsFormProps> = ({list, onSuccessCreation}) => {
    const form = useFormInstance<FormValues>()

    const onCreate = () => {
        const values = form.getFieldsValue()
        const kinds: [string, number][] = values.list.map(item => [item.name, item.amount])
        const layouts = createLayouts(kinds, values.columns)
        onSuccessCreation(layouts)
    }

    const onCopy = () => {
        const formList = form.getFieldsValue().list
        const input = 'Входные данные:\n' + formList.map(item => `${item.name.trim()} - ${item.amount}` ).join('\n')
        
        const builds = '\n \nСформированные сборки:\n' + list.map((item, i) => {
            const isOneEdition = (new Set(item.map(item => item[0]))).size === 1 // является одним тиражом

            if (isOneEdition) {
                const res = formList.find(listItem => listItem.name === item[0][0])
                // @ts-ignore
                const {name, amount} = res
                return `Сборка №${i+1}:\n` + `${name} - ${amount}`
            } else {
                return `Сборка №${i+1}:\n` + (item.map(([name, amount]) => `${name} - ${amount}`).join('\n'))
            }
        }).join('\n')

        copy(input + builds)
    }

    return (
        <>
            <Space style={{width: '100%'}} size={18} direction={'vertical'}>
            <Row gutter={24} >
                {/* <Col sm={2}>
                    Этикетка
                </Col> */}
                <Col sm={20}>
                    Наименование
                </Col>
                <Col >
                    Тираж
                </Col>
            </Row>

            <FormList/>

            <Flex justify={'space-between'}>
                <Space>
                    <Form.Item name={'columns'} label={'Ручьи'} noStyle><InputNumber placeholder={'Ручьи'}/></Form.Item>
                    <Button type={'primary'} onClick={onCreate}>Смормировать сборки</Button>
                </Space>
                <Space>
                    <Button icon={<ShareAltOutlined />}>Поделиться в Telegram</Button>
                    <Button icon={<CopyOutlined />} onClick={onCopy}>Скопировать</Button>
                </Space>
            </Flex>
            
            <BuildInfo list={list}/>
            </Space>

            <Space style={{position: 'absolute', right: 0, bottom: 0}} direction={'vertical'}>
                <Button onClick={() => form.setFieldsValue({columns: 4, list: createList(prodactinvest)})}>Продконтрактинвест</Button>
                <Button onClick={() => form.setFieldsValue({columns: 3, list: createList(detoks365)})}>365 Детокс</Button>
                <Button onClick={() => form.setFieldsValue({columns: 2, list: createList(amp)})}>АМР</Button>
                <Button onClick={() => form.setFieldsValue({columns: 4, list: createList(liniyaVkusa)})}>Линия вкуса</Button>
                <Button onClick={() => form.setFieldsValue({columns: 2, list: createList(zavodBytHimii)})}>Завод быт. химии</Button>
            </Space>
        </>
    )
}

export default CombinationsForm