import { Card,  Flex } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import Title from 'antd/es/typography/Title'
import _ from 'lodash'
import {FC} from 'react'
 
interface ListOfEditionsProps {
    list: [string, number][][]
}
 
const ListOfEditions: FC<ListOfEditionsProps> = ({list}) =>  {
    const columns = useWatch('columns') | 0

    return (
        <>
            {list.sort((a, b) => b.length - a.length ).map((edition, index) => {
                const widthPerOne = 100 / columns
                const groups = _.groupBy(edition, '0')

                return <div key={index}>
                    <Title level={4}>Сборка №{index + 1}</Title>
                    <Flex gap={24}>
                        {Object.keys(groups).map((name, index) => {
                            const item = groups[name]

                            if (item.length > 1) {
                                return <Flex 
                                    gap={24} 
                                    style={{
                                        width: `${widthPerOne * item.length}%`, backgroundColor: '#dff0e8',
                                        padding: '6px',
                                        borderRadius: '10px'
                                    }} 
                                    key={index + name}
                                    >
                                    {item.map((item, j) => {
                                        const columns = item.length
                                        const widthPerOne = 100 / columns

                                        return <Card
                                        style={{width: `${widthPerOne * item.length}%`}} 
                                        size={'small'} 
                                        title={item[1]} 
                                        key={j + name}
                                    >{item[0]}</Card>
                                    })}
                                </Flex>
                            } else {
                                const kind = item[0]

                                return <Card 
                                    style={{width: `${widthPerOne}%`}} 
                                    size={'small'} 
                                    title={kind[1]} 
                                    key={index + kind[0]}
                                >{kind[0]}</Card>
                            }

                            
                        })}
                    </Flex>
                </div>

                // return <div key={index}>
                //     <Title level={4}>Сборка №{index + 1}</Title>
                //     <Flex gap={24}>
                //         {edition.map(([name, amount], index) => {
                //             return <Card style={{width: `${widthPerOne}%`}} size={'small'} title={amount} key={index + name + amount}>{name}</Card>
                //         })}
                //     </Flex>
                // </div>
            })}
        </>
    )
}
 
export default ListOfEditions