export const liniyaVkusa: [string, number][] = [
    ['итальян травы с мор солью ', 6000], // 9067
    ['черн пер гор ', 10000], // 9072
    ['пер чил остр с мор солью ', 5000], // 9068
    ['прип 4 пер ', 10000], // 9069
    ['прип для кур с мор солью ', 25000], // 9065
    ['прип для мяс с мор солью ', 20000], // 9066
    ['прип смесь перц с мор солью ', 10000], // 9070
    ['прип универс с мор солью', 15000], // 9071
    ['чесн травы соль с мор солью ', 20000], // 9073
    ['прип для рыбы с мор солью ', 10000], // 6095
]