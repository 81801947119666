export const detoks365: [string, number][] = [
    // 11494
    ['сок green', 7000],
    // 11482
    ['сок happy green', 4000],
    // 11485
    ['сок glow', 4000],
    // 11488
    ['натуральный лимонад black', 4000],
    // 11489
    ['голубой лимонад blue lemonade', 5000],
    // 11490
    ['натуральный лимонад tropic', 2000],
    // 11491
    ['натуральный лимонад tropic', 4000],
]