export const prodactinvest: [string, number][] = [
    // скрин 11599
    ['Но кус мяс в желе гов', 1500],
    ['Но кус мяс в желе кро сердце', 1500],
    ['Но кус мяса в желе лос, суд, тун', 1500],
    ['Но кус мяс в желе кур', 1500],

    // скрин 11600
    ['Но кус мяс в желе', 1500],
    ['Но кус мяс в соусе тел инд', 1500],
    ['Но кус мяс в соусе гов', 1500],
    ['Ам мяс асс в желе', 1500],
    
    // скрин 11601
    ['Но кус мяс в желе цып кро', 1000],
    ['Но кус мяс в желе тел яг', 1000],
    ['Но кус мяс в желе тел инд', 1000],
    ['Но кус мяс в соусе кур', 1000],
    
    // скрин 11602
    ['Но кус мяс в соусе лос, суд, тун', 1000],
    ['Но кус мяс в соусе кро сер', 1000],
    ['Но кус мяс в соусе мяс асс', 1000],
    ['Но кус мяс в соусе гов', 1000],

    // скрин 11603
    ['Ночной охотник Кусочки мяса в соусе Курица', 1000],
    ['Puffins Мясное ассорти в нежном желе', 1000],
    ['Puffins Говядина в аппетитном желе', 1000],
    ['Puffins Сочные кусочки курицы в желе', 1000],

    // скрин 11604
    ['Ночной охотник кусочки мяса в желе мяснное ассорти', 2000],
    ['Амур говядина в соусе', 2000],
    ['Амур индейка в желе', 2000],
    ['Амур кролик в соусе', 2000],

    // скрин 11605
    ['Puffins сочные кусочки телятины с печенью в желе', 400],
    ['Puffins сочные кусочки курицы в желе', 400],
    ['Puffins рыбное ассорти в нежном желе', 400],
    ['Puffins говядина в аппетитном желе', 400],

    // скрин 11606
    ['Puffins мясное ассорти в нежном соусе', 300],
    ['Puffins рыбное ассорти в нежном соусе', 300],
    ['Puffins сочные кусочки курицы в соусе', 300],
    ['Puffins говядина в аппетитном соусе', 300],

    // скрин 11607
    ['Амур курица в желе', 3000],
    ['Амур говядина в желе', 3000],

    // скрин 11588
    ['Puffins сочные кусочки телятины с печенью в соусе', 300],
    ['Puffins сочные кусочки ягненка в соусе', 300],
    ['Верные друзья кусочки мяса в соусе телятина и ягненок', 300],
    ['Верные друзья кусочки мяса в соусе мясное ассорти', 300],

    // скрин 11589
    ['Ам кур в соусе', 2300],
    ['Ам яг в желе', 2300],
    ['Ам утка с морк в желе', 2300],

    // скрин 11590
    ['Ам кро в желе', 3000],
    ['Ам яг в соусе', 1500],
    ['Ам мяс ас в соусе', 1500],

    // скрин 11591
    ['Pu мяс ас в неж соусе', 500],
    ['Pu соч кус яг в соусе', 500],
    ['Pu рыб ас в неж соусе', 500],
    ['Pu соч кус кур в соусе', 500],

    // скрин 11592
    ['He мяс ас с лист брус', 500],
    ['He кур в соусе с мор кап', 500],
    ['He апп кро в соусе с трав', 500],
    ['He кус мяс в сыр соусе яг', 500],

    // скрин 11593
    ['He неж тел в соусе с лист брус', 500],
    ['He неж яг в соусе с мор кап', 500],
    ['Pu рыб ас в неж желе', 1000],

    // скрин 11594
    ['Pu соч кус яг в желе',  1000],
    ['Pu соч кус тел с печ в желе', 1000],
    ['Ам утк с мор в соусе', 2000],

    // скрин 11595
    ['Pu ', 400],
    ['Ве', 200],

    // скрин 11596
    ['Но кус мяс в сыр смет соусе кур', 600],
    ['Но кус мяс в смет соусе кро', 600],

    // скрин 11597
    ['He рыб ас в соусе с мят', 800],

    // скрин 11598
    ['Ам гов в соусе', 1800],
]